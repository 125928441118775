import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  TRANSLATION: 'TRANSLATION',

  // getTranslation
  GETTRANSLATION_REQUEST: 'GETTRANSLATION_REQUEST',
  GETTRANSLATION_ERROR: 'GETTRANSLATION_ERROR',
  GETTRANSLATION_SUCCESS: 'GETTRANSLATION_SUCCESS'
}

const getTranslation = () => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETTRANSLATION_REQUEST))
  return await serviceController(routes.getTranslation) //`${routes.getTranslation}?lang=${lang}`
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETTRANSLATION_SUCCESS, res.data.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETTRANSLATION_ERROR, error.message)))
}

const setLangStore = (type, data) => {
  return ({
    type,
    data
  })
}

export const translation = {
  getTranslation,
  setLangStore
}