import _ from 'underscore'
import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

import { calcuPromo, productPromo, breadcrumb } from '../../utils'

export const actionTypes = {
  // getProduct
  GETPRODUCT_REQUEST: 'GETPRODUCT_REQUEST',
  GETPRODUCT_ERROR: 'GETPRODUCT_ERROR',
  GETPRODUCT_SUCCESS: 'GETPRODUCT_SUCCESS',

  // getProductCategory
  GETPRODUCTCATEGORY_REQUEST: 'GETPRODUCTCATEGORY_REQUEST',
  GETPRODUCTCATEGORY_ERROR: 'GETPRODUCTCATEGORY_ERROR',
  GETPRODUCTCATEGORY_SUCCESS: 'GETPRODUCTCATEGORY_SUCCESS',

  // getProductById
  GETPRODUCTBYID_REQUEST: 'GETPRODUCTBYID_REQUEST',
  GETPRODUCTBYID_ERROR: 'GETPRODUCTBYID_ERROR',
  GETPRODUCTBYID_SUCCESS: 'GETPRODUCTBYID_SUCCESS',

  // checkProducyQty
  CHECKPRODUCTQTY_REQUEST: 'CHECKPRODUCTQTY_REQUEST',
  CHECKPRODUCTQTY_ERROR: 'CHECKPRODUCTQTY_ERROR',
  CHECKPRODUCTQTY_SUCCESS: 'CHECKPRODUCTQTY_SUCCESS',

  // requestQuotation
  REQUESTQUOTATION_REQUEST: 'REQUESTQUOTATION_REQUEST',
  REQUESTQUOTATION_ERROR: 'REQUESTQUOTATION_ERROR',
  REQUESTQUOTATION_SUCCESS: 'REQUESTQUOTATION_SUCCESS',

  // getFeatureProduct
  GETFEATUREPRODUCT_REQUEST: 'GETFEATUREPRODUCT_REQUEST',
  GETFEATUREPRODUCT_ERROR: 'GETFEATUREPRODUCT_ERROR',
  GETFEATUREPRODUCT_SUCCESS: 'GETFEATUREPRODUCT_SUCCESS',

  // getFeatureProductDetail
  GETFEATUREPRODUCTDETAIL_REQUEST: 'GETFEATUREPRODUCTDETAIL_REQUEST',
  GETFEATUREPRODUCTDETAIL_ERROR: 'GETFEATUREPRODUCTDETAIL_ERROR',
  GETFEATUREPRODUCTDETAIL_SUCCESS: 'GETFEATUREPRODUCTDETAIL_SUCCESS',
}

const getProduct = (page_number, product_per_page, sort_by, categ_id, brand_id, product_name, lang, keywords, customer_type, pricelist_id, min_price, max_price, country_id) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETPRODUCT_REQUEST))
  return await serviceController(`${routes.getProduct}?page_number=${page_number}&product_per_page=${product_per_page}&sort_by=${sort_by}&categ_id=${categ_id}&brand_id=${brand_id}&product_name=${product_name}&lang=${lang}&keywords=${keywords}&customer_type=${customer_type}&pricelist_id=${pricelist_id}&min_price=${min_price}&max_price=${max_price}&country_id=${country_id}`)
    .then(async res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        let result = await calcuPromo(res.data.data)
        dispatch(ToDoSuccess(actionTypes.GETPRODUCT_SUCCESS, result))
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETPRODUCT_ERROR, error.message)))
}

const getProductCategory = (lang, division_id) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETPRODUCTCATEGORY_REQUEST))
  return await serviceController(`${routes.getProductCategory}?lang=${lang}&division_id=${division_id}`)
    .then(async res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        // use use breadcrumb function for generate breadcrumb json 
        let tmp = await breadcrumb(res.data)
        dispatch(ToDoSuccess(actionTypes.GETPRODUCTCATEGORY_SUCCESS, tmp))
        return tmp
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETPRODUCTCATEGORY_ERROR, error.message)))
}

const productCategoryChange = (array, id, selectIndex) => async dispatch => {
  let tmp = array
  if (tmp?.data?.length > 0) {
    tmp?.data?.map(x => {
      if (x?.sub_category?.length > 0) {
        let new_res = x?.sub_category?.filter(y => y.category_id === id)
        if (new_res?.length > 0) {
          let new_breadcrumb = {
            ...x,
            selectIndex,
            sub_category: new_res
          }
          delete tmp.breadcrumbData
          tmp.breadcrumbData = new_breadcrumb
          return true
        }
      } else {
        let new_res = tmp?.data?.filter(y => y?.category_id === id)
        if (new_res?.length > 0) {
          delete tmp.breadcrumbData
          tmp.breadcrumbData = {
            ...new_res[0],
            selectIndex
          }
          return true
        }
      }
    })
    dispatch(ToDoSuccess(actionTypes.GETPRODUCTCATEGORY_SUCCESS, tmp))
  }
}

const getProductById = (product_id, lang, customer_type, pricelist_id) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETPRODUCTBYID_REQUEST))
  return await serviceController(`${routes.getProductById}?product_id=${product_id}&lang=${lang}&customer_type=${customer_type}&pricelist_id=${pricelist_id}`)
    .then(async res => {
      if (res.data.data.error) {
        return res.data
      } else {
        let calc_data = productPromo(res?.data?.data[0], 1)
        let relat = calc_data?.categories[0]?.id && await dispatch(getProduct('', '', '', calc_data?.categories[0]?.id, '', '', lang, '', '', pricelist_id))
        let result = relat && await calcuPromo(relat.data)
        calc_data.relatedProduct = result ? _.sample(result?.product_list?.length > 0 ? result?.product_list : [], 6) : []
        dispatch(ToDoSuccess(actionTypes.GETPRODUCTBYID_SUCCESS, calc_data))
        return calc_data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETPRODUCTBYID_ERROR, error.message)))
}

const checkProductQty = (product_id, variant_id, qty, product_detail) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.CHECKPRODUCTQTY_REQUEST))
  return await serviceController(`${routes.checkProductQty}?product_id=${product_id}&variant_id=${variant_id}&qty=${qty}`)
    .then(res => {
      if (res.data) {
        if (product_detail) {
          let calc_data = productPromo(product_detail, qty)
          dispatch(ToDoSuccess(actionTypes.GETPRODUCTBYID_SUCCESS, calc_data))
        }
        dispatch(ToDoSuccess(actionTypes.CHECKPRODUCTQTY_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.CHECKPRODUCTQTY_ERROR, error.message)))
}

const requestQuotation = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.REQUESTQUOTATION_REQUEST))
  return await serviceController(routes.requestQuotation, data)
    .then(res => {
      if (res?.data?.result?.status === 'success') {
        dispatch(ToDoSuccess(actionTypes.REQUESTQUOTATION_SUCCESS, res?.data))
        return res.data
      } else {
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.REQUESTQUOTATION_ERROR, error.message)))
}

const getFeatureProduct = (division_id, lang) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETFEATUREPRODUCT_REQUEST))
  return await serviceController(`${routes.getFeatureProduct}?division_id=${division_id}&lang=${lang}`)
    .then(async res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETFEATUREPRODUCT_SUCCESS, res?.data))
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETFEATUREPRODUCT_ERROR, error.message)))
}

const getFeatureProductDetail = (id, lang) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETFEATUREPRODUCTDETAIL_REQUEST))
  return await serviceController(`${routes.getFeatureProductDetail}?id=${id}&lang=${lang}`)
    .then(async res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETFEATUREPRODUCTDETAIL_SUCCESS, res?.data))
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETFEATUREPRODUCTDETAIL_ERROR, error.message)))
}

export const Product = {
  getProduct,
  getProductCategory,
  getProductById,
  checkProductQty,
  requestQuotation,
  productCategoryChange,
  getFeatureProduct,
  getFeatureProductDetail
}