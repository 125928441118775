import { actionTypes } from '../actions/news.action'

const initialState = {
  error: null,
  isNewsLoading: false,
  news_data: null,
  newsById_data: null
}

const news = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETNEWS_REQUEST: // getCareer
      return {
        ...state,
        isNewsLoading: true
      }
    case actionTypes.GETNEWS_SUCCESS:
      return {
        ...state,
        isNewsLoading: false,
        news_data: action.data
      }
    case actionTypes.GETNEWS_ERROR:
      return {
        ...state,
        isNewsLoading: false,
        error: action.error
      }

    case actionTypes.GETNEWSID_REQUEST: // getCareerById
      return {
        ...state,
        isNewsLoading: true
      }
    case actionTypes.GETNEWSID_SUCCESS:
      return {
        ...state,
        isNewsLoading: false,
        newsById_data: action.data
      }
    case actionTypes.GETNEWSID_ERROR:
      return {
        ...state,
        isNewsLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default news