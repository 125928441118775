import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  TERMS: 'TERMS',

  // getTermsCondition
  GETTERMS_REQUEST: 'GETTERMS_REQUEST',
  GETTERMS_ERROR: 'GETTERMS_ERROR',
  GETTERMS_SUCCESS: 'GETTERMS_SUCCESS',
}

const getTermsCondition = lang => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETTERMS_REQUEST))
  return await serviceController(`${routes.getTermsCondition}?lang=${lang}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETTERMS_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETTERMS_ERROR, error.message)))
}

export const terms = {
  getTermsCondition
}