import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

import { isBetweenDateRange, calcuPromo } from '../../utils'

export const actionTypes = {
  // getPromotion
  GETPROMOTION_REQUEST: 'GETPROMOTION_REQUEST',
  GETPROMOTION_ERROR: 'GETPROMOTION_ERROR',
  GETPROMOTION_SUCCESS: 'GETPROMOTION_SUCCESS',

  // getPromotionById
  GETPROMOTIONBYID_REQUEST: 'GETPROMOTIONBYID_REQUEST',
  GETPROMOTIONBYID_ERROR: 'GETPROMOTIONBYID_ERROR',
  GETPROMOTIONBYID_SUCCESS: 'GETPROMOTIONBYID_SUCCESS'
}

const getPromotion = (lang, division_id, home_page_status, customer_type) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETPROMOTION_REQUEST))
  return await serviceController(`${routes.getPromotion}?lang=${lang}&division_id=${division_id}&home_page_status=${home_page_status}&customer_type=${customer_type}`)
    .then(async res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        let tmp = await isBetweenDateRange(res.data)
        dispatch(ToDoSuccess(actionTypes.GETPROMOTION_SUCCESS, tmp))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETPROMOTION_ERROR, error.message)))
}

const getPromotionById = (page_number, product_per_page, lang, promotion_listing_id, customer_type) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETPROMOTIONBYID_REQUEST))
  return await serviceController(`${routes.getPromotionById}?page_number=${page_number}&product_per_page=${product_per_page}&lang=${lang}&promotion_listing_id=${promotion_listing_id}&customer_type=${customer_type}`)
    .then(async res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        let tmp = await calcuPromo(res?.data?.data)
        console.log('convert', tmp)
        dispatch(ToDoSuccess(actionTypes.GETPROMOTIONBYID_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETPROMOTIONBYID_ERROR, error.message)))
}

export const Promotion = {
  getPromotion,
  getPromotionById
}