import { actionTypes } from '../actions/wishlist.action'

const initialState = {
  error: null,
  isLoading: false,
  wishlist_data: null,
  createwishlist_data: null,
  removewishlist_data: null,
  existwishlist_data: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETWISHLIST_REQUEST: // getWishlist
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETWISHLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wishlist_data: action.data
      }
    case actionTypes.GETWISHLIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.CREATEWISHLIST_REQUEST: // createWishlist
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.CREATEWISHLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createwishlist_data: action.data
      }
    case actionTypes.CREATEWISHLIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.REMOVEWISHLIST_REQUEST: // removeWishlist
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.REMOVEWISHLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        removewishlist_data: action.data
      }
    case actionTypes.REMOVEWISHLIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.EXISTWISHLIST_REQUEST: // existWishlist
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.EXISTWISHLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        existwishlist_data: action.data
      }
    case actionTypes.EXISTWISHLIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}