import { actionTypes } from '../actions/pagecode.action'

const initialState = {
  error: null,
  isLoading: false,
  pageCode_data: null
}

const pageCode = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETPAGECODE_REQUEST: // getPageCode
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETPAGECODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pageCode_data: action.data
      }
    case actionTypes.GETPAGECODE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default pageCode