import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'
import { checkChange, getCheckId } from '../../utils/utils'

export const actionTypes = {
  DIVISION: 'DIVISION',

  // getDivision
  GETDIVISION_REQUEST: 'GETDIVISION_REQUEST',
  GETDIVISION_ERROR: 'GETDIVISION_ERROR',
  GETDIVISION_SUCCESS: 'GETDIVISION_SUCCESS',

  // getDivisionById
  GETDIVISIONID_REQUEST: 'GETDIVISIONID_REQUEST',
  GETDIVISIONID_ERROR: 'GETDIVISIONID_ERROR',
  GETDIVISIONID_SUCCESS: 'GETDIVISIONID_SUCCESS',

   // getPrinciple
   GETPRINCIPLE_REQUEST: 'GETPRINCIPLE_REQUEST',
   GETPRINCIPLE_ERROR: 'GETPRINCIPLE_ERROR',
   GETPRINCIPLE_SUCCESS: 'GETPRINCIPLE_SUCCESS'
}

const getDivision = lang => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETDIVISION_REQUEST))
  return await serviceController(`${routes.getDivision}?lang=${lang}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        res.data.division_ids = []
        dispatch(ToDoSuccess(actionTypes.GETDIVISION_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETDIVISION_ERROR, error.message)))
}

const getDivisionById = (id, lang) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETDIVISIONID_REQUEST))
  return await serviceController(`${routes.getDivisionById}${id}?lang=${lang}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETDIVISIONID_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETDIVISIONID_ERROR, error.message)))
}

const getPrinciple = (id, lang) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETPRINCIPLE_REQUEST))
  return await serviceController(`${routes.getPrinciple}?division_id=${id}&lang=${lang}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        res.data.division_ids = []
        dispatch(ToDoSuccess(actionTypes.GETPRINCIPLE_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETPRINCIPLE_ERROR, error.message)))
}

const getCheckChange = (type, array, data) => async dispatch => {
  let res = await checkChange(type, array, data)
  let id_res = await getCheckId(type, res)
  let data_res = {
    count: res?.length,
    division_ids: id_res,
    data: res
  }
  dispatch(ToDoSuccess(actionTypes.GETDIVISION_SUCCESS, data_res))
}

export const division = {
  getDivision,
  getDivisionById,
  getPrinciple,
  getCheckChange
}