import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

import { radioCheckChange, getCheckId } from '../../utils'

export const actionTypes = {
  // createCart
  CREATECART_REQUEST: 'CREATECART_REQUEST',
  CREATECART_ERROR: 'CREATECART_ERROR',
  CREATECART_SUCCESS: 'CREATECART_SUCCESS',

  // getCart
  GETCART_REQUEST: 'GETCART_REQUEST',
  GETCART_ERROR: 'GETCART_ERROR',
  GETCART_SUCCESS: 'GETCART_SUCCESS',

  // deleteCart
  DELETECART_REQUEST: 'DELETECART_REQUEST',
  DELETECART_ERROR: 'DELETECART_ERROR',
  DELETECART_SUCCESS: 'DELETECART_SUCCESS',

  // deleteAllInCart
  DELETEALLINCART_REQUEST: 'DELETEALLINCART_REQUEST',
  DELETEALLINCART_ERROR: 'DELETEALLINCART_ERROR',
  DELETEALLINCART_SUCCESS: 'DELETEALLINCART_SUCCESS',

  // applyPromoCode
  APPLYPROMOCODE_REQUEST: 'APPLYPROMOCODE_REQUEST',
  APPLYPROMOCODE_ERROR: 'APPLYPROMOCODE_ERROR',
  APPLYPROMOCODE_SUCCESS: 'APPLYPROMOCODE_SUCCESS',

  // uploadDoctorLetter
  UPLOADDOCTORLETTER_REQUEST: 'UPLOADDOCTORLETTER_REQUEST',
  UPLOADDOCTORLETTER_ERROR: 'UPLOADDOCTORLETTER_ERROR',
  UPLOADDOCTORLETTER_SUCCESS: 'UPLOADDOCTORLETTER_SUCCESS',

  // createShipping
  CREATESHIPPING_REQUEST: 'CREATESHIPPING_REQUEST',
  CREATESHIPPING_ERROR: 'CREATESHIPPING_ERROR',
  CREATESHIPPING_SUCCESS: 'CREATESHIPPING_SUCCESS',

  // getPaymentList
  GETPAYMENTLIST_REQUEST: 'GETPAYMENTLIST_REQUEST',
  GETPAYMENTLIST_ERROR: 'GETPAYMENTLIST_ERROR',
  GETPAYMENTLIST_SUCCESS: 'GETPAYMENTLIST_SUCCESS',

  // payNow
  PAYNOW_REQUEST: 'PAYNOW_REQUEST',
  PAYNOW_ERROR: 'PAYNOW_ERROR',
  PAYNOW_SUCCESS: 'PAYNOW_SUCCESS'
}

const createCart = (data, uid, lang) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.CREATECART_REQUEST))
  return await serviceController(routes.createCart, data)
    .then(res => {
      if (res?.data?.result[0]?.status !== 'fail') {
        dispatch(ToDoSuccess(actionTypes.CREATECART_SUCCESS, res.data))
        dispatch(setOrderStore('ORDER_STORE_OBJ', res.data?.result[0]))
        dispatch(getCart(res?.data?.result[0]?.order_id, uid || '', lang))
        return res.data
      } else {
        return res.data
      }

      // if(res?.data?.error){
      // }else{
      // }
    })
    .catch(error => dispatch(ToDoError(actionTypes.CREATECART_ERROR, error.message)))
}

const getCart = (order_id, user_id, lang) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETCART_REQUEST))
  return await serviceController(`${routes.getCart}?order_id=${order_id}&user_id=${user_id}&lang=${lang}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETCART_SUCCESS, res.data))
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETCART_ERROR, error.message)))
}

const deleteCart = (data, langCode) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.DELETECART_REQUEST))
  return await serviceController(routes.deleteCart, data)
    .then(res => {
      if (res?.data?.result?.status === 'success') {
        dispatch(ToDoSuccess(actionTypes.DELETECART_SUCCESS, res.data))
        dispatch(getCart(data?.data?.order_id, data?.data?.user_id || '', langCode))
        return res.data
      } else {
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.DELETECART_ERROR, error.message)))
}

const deleteAllInCart = (data, langCode) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.DELETEALLINCART_REQUEST))
  return await serviceController(routes.deleteAllInCart, data)
    .then(res => {
      if (res?.data?.result?.status === 'success') {
        dispatch(getCart(data?.data?.order_id, '', langCode))
        dispatch(ToDoSuccess(actionTypes.DELETEALLINCART_SUCCESS, res.data))
        return res.data
      } else {
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.DELETEALLINCART_ERROR, error.message)))
}

const applyPromoCode = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.APPLYPROMOCODE_REQUEST))
  return await serviceController(routes.applyPromoCode, data)
    .then(res => {
      if (res?.data?.result?.status === 'success') {
        dispatch(ToDoSuccess(actionTypes.APPLYPROMOCODE_SUCCESS, res.data))
        return res.data
      } else {
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.APPLYPROMOCODE_ERROR, error.message)))
}

const createShipping = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.CREATESHIPPING_REQUEST))
  return await serviceController(routes.createShipping, data)
    .then(res => {
      if (res?.data?.status === 'fail') {
        return res.data
      } else {
        dispatch(ToDoSuccess(actionTypes.CREATESHIPPING_SUCCESS, res.data))
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.CREATESHIPPING_ERROR, error.message)))
}

const getPaymentList = () => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETPAYMENTLIST_REQUEST))
  return await serviceController(routes.getPaymentList)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        res.data.selected_id = res?.data?.data[0]?.payment_method_id
        res.data.data[0].check = true
        dispatch(ToDoSuccess(actionTypes.GETPAYMENTLIST_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETPAYMENTLIST_ERROR, error.message)))
}

const getPaymentChange = (type, array, id) => async dispatch => {
  let res = await radioCheckChange(type, array, id)
  let id_res = await getCheckId(type, res)
  let data = {
    count: res?.length,
    selected_id: id_res[0],
    data: res
  }
  dispatch(ToDoSuccess(actionTypes.GETPAYMENTLIST_SUCCESS, data))
}

const payNow = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.PAYNOW_REQUEST))
  return await serviceController(routes.payNow, data)
    .then(res => {
      if (res.data.result.status === 'success') {
        dispatch(ToDoSuccess(actionTypes.PAYNOW_SUCCESS, res.data))
        return res.data
      } else {
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.PAYNOW_ERROR, error.message)))
}

const setOrderStore = (type, data) => {
  return ({
    type,
    data
  })
}

export const Ecommerce = {
  createCart,
  getCart,
  deleteCart,
  deleteAllInCart,
  applyPromoCode,
  createShipping,
  getPaymentList,
  getPaymentChange,
  payNow,
  setOrderStore,
}