import { actionTypes } from '../actions/order.action'

const initialState = {
  error: null,
  isLoading: false,
  order_data: null,
  orderbyid_data: null,
  orderbypdf_data: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETORDER_REQUEST: // GETORDER
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        order_data: action.data
      }
    case actionTypes.GETORDER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETORDERBYID_REQUEST: // GETORDERBYID
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETORDERBYID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orderbyid_data: action.data
      }
    case actionTypes.GETORDERBYID_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETORDERBYPDF_REQUEST: // GETORDERBYPDF
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETORDERBYPDF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orderbypdf_data: action.data
      }
    case actionTypes.GETORDERBYPDF_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}