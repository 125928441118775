import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  CUSTOMERSPECIALIST: 'CUSTOMERSPECIALIST',

  // getCUSTOMERSPECIALIST
  GETCUSTOMERSPECIALIST_REQUEST: 'GETCUSTOMERSPECIALIST_REQUEST',
  GETCUSTOMERSPECIALIST_ERROR: 'GETCUSTOMERSPECIALIST_ERROR',
  GETCUSTOMERSPECIALIST_SUCCESS: 'GETCUSTOMERSPECIALIST_SUCCESS',
}

const getCustomerSpecialist = (customertype) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETCUSTOMERSPECIALIST_REQUEST))
  return await serviceController(`${routes.getCustomerSpecialist}?customertype=${customertype}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETCUSTOMERSPECIALIST_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETCUSTOMERSPECIALIST_ERROR, error.message)))
}

export const customerspecialist = {
  getCustomerSpecialist
}