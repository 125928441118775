import { combineReducers } from 'redux'

import aboutUs from './aboutus.reducer'

import blog from './blog.reducer'

import business from './business.reducer'

import career from './career.reducer'

import contact from './contactus.reducer'

import division from './division.reducer'

import industry from './industry.reducer'

import news from './news.reducer'

import location from './location.reducer'

import onlineStore from './onlinestore.reducer'

import pageCode from './pagecode.reducer'

import terms from './terms.reducer'

import websiteGallery from './website.reducer'

import translation from './translation.reducer'

import emit from './emit.reducer'

import banner from './banner.reducer'

import product from './product.reducer'

import profile from './profile.reducer'

import ecommerce from './ecommerce.reducer'

import customerspecialist from './customerspecialist.reducer'

import cityList from './citylist.reducer'

import brand from './brand.reducer'

import order from './order.reducer'

import wishlist from './wishlist.reducer'

import promotion from './promotion.reducer'

export default combineReducers({
  aboutUs,
  blog,
  business,
  career,
  contact,
  division,
  industry,
  news,
  location,
  onlineStore,
  pageCode,
  terms,
  websiteGallery,
  translation,
  emit,
  banner,
  product,
  profile,
  ecommerce,
  customerspecialist,
  cityList,
  brand,
  order,
  wishlist,
  promotion
})