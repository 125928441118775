import { actionTypes } from '../actions/career.action'

const initialState = {
  error: null,
  isLoading: false,
  career_data: null,
  careerById_data: null
}

const career = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETCAREER_REQUEST: // getCareer
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETCAREER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        career_data: action.data
      }
    case actionTypes.GETCAREER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    case actionTypes.GETCAREERID_REQUEST: // getCareerById
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETCAREERID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        careerById_data: action.data
      }
    case actionTypes.GETCAREERID_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default career