import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  NEWS: 'NEWS',

  // getLatestNew
  GETNEWS_REQUEST: 'GETNEWS_REQUEST',
  GETNEWS_ERROR: 'GETNEWS_ERROR',
  GETNEWS_SUCCESS: 'GETNEWS_SUCCESS',

  // getLatestNewById
  GETNEWSID_REQUEST: 'GETNEWSID_REQUEST',
  GETNEWSID_ERROR: 'GETNEWSID_ERROR',
  GETNEWSID_SUCCESS: 'GETNEWSID_SUCCESS'
}

const getKnowledgeNew = (lang, per_page, page_number, division_ids, all, other, customer_type) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETNEWS_REQUEST))
  return await serviceController(`${routes.getLatestNew}?lang=${lang}&per_page=${per_page}&page_number=${page_number}&division_ids=${division_ids}&all=${all}&other=${other}&customer_type=${customer_type}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETNEWS_SUCCESS, res.data))
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETNEWS_ERROR, error.message)))
}

const getKnowledgeNewById = (lang, news_id) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETNEWSID_REQUEST))
  return await serviceController(`${routes.getLatestNewById}?lang=${lang}&news_id=${news_id}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETNEWSID_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETNEWSID_ERROR, error.message)))
}

export const news = {
  getKnowledgeNew,
  getKnowledgeNewById
}