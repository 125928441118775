import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  WISHLIST: 'WISHLIST',

  // getWishlist
  GETWISHLIST_REQUEST: 'GETWISHLIST_REQUEST',
  GETWISHLIST_ERROR: 'GETWISHLIST_ERROR',
  GETWISHLIST_SUCCESS: 'GETWISHLIST_SUCCESS',

  // createWishlist
  CREATEWISHLIST_REQUEST: 'CREATEWISHLIST_REQUEST',
  CREATEWISHLIST_ERROR: 'CREATEWISHLIST_ERROR',
  CREATEWISHLIST_SUCCESS: 'CREATEWISHLIST_SUCCESS',

  // removeWishlist
  REMOVEWISHLIST_REQUEST: 'REMOVEWISHLIST_REQUEST',
  REMOVEWISHLIST_ERROR: 'REMOVEWISHLIST_ERROR',
  REMOVEWISHLIST_SUCCESS: 'REMOVEWISHLIST_SUCCESS',

  // existWishlist
  EXISTWISHLIST_REQUEST: 'EXISTWISHLIST_REQUEST',
  EXISTWISHLIST_ERROR: 'EXISTWISHLIST_ERROR',
  EXISTWISHLIST_SUCCESS: 'EXISTWISHLIST_SUCCESS',
}

// const getWishlist = (lang, user_id) => async dispatch => {
//   dispatch(ToDoRequest(actionTypes.GETWISHLIST_REQUEST))
//   return await serviceController(`${routes.getWishlist}?lang=${lang}&user_id=${user_id}`)
//     .then(res => {
//       if (res.data.data.error) {
//         console.log(res.data)
//       } else {
//         dispatch(ToDoSuccess(actionTypes.GETWISHLIST_SUCCESS, res.data))
//       }
//     })
//     .catch(error => dispatch(ToDoError(actionTypes.GETWISHLIST_ERROR, error.message)))
// }

const getWishlist = (lang, user_id) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETWISHLIST_REQUEST))
  return await serviceController(`${routes.getWishlist}?lang=${lang}&user_id=${user_id}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        let tmp = res?.data?.data.map(x=>x.qty=1)
        dispatch(ToDoSuccess(actionTypes.GETWISHLIST_SUCCESS, res.data.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETWISHLIST_ERROR, error.message)))
}

const getWishlistqtychange = (data, product_id, qty) => async dispatch => {
  let tmp = data?.filter(x => x.product_id === product_id).map(y => y.qty = qty)
  console.log('wishlist data', data)
  dispatch(ToDoSuccess(actionTypes.GETWISHLIST_SUCCESS, data))
 }

const createWishlist = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.CREATEWISHLIST_REQUEST))
  return await serviceController(`${routes.createWishlist}`, data)
    .then(res => {
      if (res.data.result.status === 'success') {
        dispatch(ToDoSuccess(actionTypes.CREATEWISHLIST_SUCCESS, res.data))
        return res.data
      } else {
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.CREATEWISHLIST_ERROR, error.message)))
}

const removeWishlist = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.REMOVEWISHLIST_REQUEST))
  return await serviceController(`${routes.removeWishlist}`, data)
    .then(res => {
      if (res.data.result.status === 'success') {
        dispatch(ToDoSuccess(actionTypes.REMOVEWISHLIST_SUCCESS, res.data))
        return res.data
      } else {
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.REMOVEWISHLIST_ERROR, error.message)))
}

const existsWishlist = (user_id, variant_id, product_id) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.EXISTWISHLIST_REQUEST))
  return await serviceController(`${routes.getExistsWishlist}?user_id=${user_id}&variant_id=${variant_id}&product_id=${product_id}`)
    .then(res => {
      if (res.data.data.status === 'Does not exist!') {
        dispatch(ToDoSuccess(actionTypes.EXISTWISHLIST_SUCCESS, res.data))
        return res.data
      } else {
        dispatch(ToDoSuccess(actionTypes.EXISTWISHLIST_SUCCESS, res.data))
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.EXISTWISHLIST_ERROR, error.message)))
}

export const wishlist = {
  getWishlist,
  getWishlistqtychange,
  createWishlist,
  removeWishlist,
  existsWishlist
}