import { actionTypes } from '../actions/industry.action'

const initialState = {
  error: null,
  isLoading: false,
  industry_data: null,
  industryById_data: null
}

const industry = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETINDUSTRY_REQUEST: // getIndustry
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETINDUSTRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        industry_data: action.data
      }
    case actionTypes.GETINDUSTRY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    case actionTypes.GETINDUSTRYID_REQUEST: // getIndustryById
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETINDUSTRYID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        industryById_data: action.data
      }
    case actionTypes.GETINDUSTRYID_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default industry