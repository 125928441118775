import { actionTypes } from '../actions/division.action'

const initialState = {
  error: null,
  isLoading: false,
  division_data: null,
  divisionById_data: null,
  principle_data: null
}

const division = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETDIVISION_REQUEST: // getDivision
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETDIVISION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        division_data: action.data
      }
    case actionTypes.GETDIVISION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    case actionTypes.GETDIVISIONID_REQUEST: // getDivisionById
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETDIVISIONID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        divisionById_data: action.data
      }
    case actionTypes.GETDIVISIONID_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETPRINCIPLE_REQUEST: // getPrinciple
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETPRINCIPLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        principle_data: action.data
      }
    case actionTypes.GETPRINCIPLE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default division