import { actionTypes } from '../actions/product.action'

const initialState = {
  error: null,
  isLoading: false,
  product_list: null,
  category_data: null,
  product_detail: null,
  checkProductQty: null,
  featured_product_list: null,
  featured_product_detail: null,
  request_quotation: { isNoti: false, alert_msg: 'send_success', alert_type: 'success' }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETPRODUCT_REQUEST: // getProduct
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETPRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        product_list: action.data
      }
    case actionTypes.GETPRODUCT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETPRODUCTCATEGORY_REQUEST: // getProductCategory
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETPRODUCTCATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        category_data: action.data
      }
    case actionTypes.GETPRODUCTCATEGORY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETPRODUCTBYID_REQUEST: // getProductById
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETPRODUCTBYID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        product_detail: action.data
      }
    case actionTypes.GETPRODUCTBYID_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.CHECKPRODUCTQTY_REQUEST: // checkProductQty
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.CHECKPRODUCTQTY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        checkProductQty: action.data
      }
    case actionTypes.CHECKPRODUCTQTY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.REQUESTQUOTATION_REQUEST: // requestQuotation
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.REQUESTQUOTATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        request_quotation: action.data
      }
    case actionTypes.REQUESTQUOTATION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETFEATUREPRODUCT_REQUEST: // getFeatureProduct
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETFEATUREPRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        featured_product_list: action.data
      }
    case actionTypes.GETFEATUREPRODUCT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETFEATUREPRODUCTDETAIL_REQUEST: // getFeatureProductDetail
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETFEATUREPRODUCTDETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        featured_product_detail: action.data
      }
    case actionTypes.GETFEATUREPRODUCTDETAIL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}