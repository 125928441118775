import { actionTypes } from '../actions/banner.action'

const initialState = {
  error: null,
  isLoading: false,
  ecBanner_data: null,
  coBanner_data: null
}

const banner = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETECBANNER_REQUEST: // getEcBanner
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETECBANNER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ecBanner_data: action.data
      }
    case actionTypes.GETECBANNER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    case actionTypes.GETCOBANNER_REQUEST: // getCoBanner
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETCOBANNER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        coBanner_data: action.data
      }
    case actionTypes.GETCOBANNER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default banner