import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  ONLINESTORE: 'ONLINESTORE',

  // getOnlineStore
  GETONLINESTORE_REQUEST: 'GETONLINESTORE_REQUEST',
  GETONLINESTORE_ERROR: 'GETONLINESTORE_ERROR',
  GETONLINESTORE_SUCCESS: 'GETONLINESTORE_SUCCESS',
}

const getOnlineStore = lang => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETONLINESTORE_REQUEST))
  return await serviceController(`${routes.getOnlineStore}?lang=${lang}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETONLINESTORE_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETONLINESTORE_ERROR, error.message)))
}

export const onlineStore = {
  getOnlineStore
}