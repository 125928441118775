import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  CAREER: 'CAREER',

  // getCareer
  GETCAREER_REQUEST: 'GETCAREER_REQUEST',
  GETCAREER_ERROR: 'GETCAREER_ERROR',
  GETCAREER_SUCCESS: 'GETCAREER_SUCCESS',

  // getCareerById
  GETCAREERID_REQUEST: 'GETCAREERID_REQUEST',
  GETCAREERID_ERROR: 'GETCAREERID_ERROR',
  GETCAREERID_SUCCESS: 'GETCAREERID_SUCCESS'
}

const getCareer = (lang, per_page, page_number) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETCAREER_REQUEST))
  return await serviceController(`${routes.getCareer}?lang=${lang}&per_page=${per_page}&page_number=${page_number}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETCAREER_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETCAREER_ERROR, error.message)))
}

const getCareerById = (career_id, lang) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETCAREERID_REQUEST))
  return await serviceController(`${routes.getCareerById}?career_id=${career_id}&lang=${lang}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETCAREERID_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETCAREERID_ERROR, error.message)))
}

export const career = {
  getCareer,
  getCareerById
}