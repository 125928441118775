import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  INDUSTRY: 'INDUSTRY',

  // getIndustry
  GETINDUSTRY_REQUEST: 'GETINDUSTRY_REQUEST',
  GETINDUSTRY_ERROR: 'GETINDUSTRY_ERROR',
  GETINDUSTRY_SUCCESS: 'GETINDUSTRY_SUCCESS',

  // getIndustryById
  GETINDUSTRYID_REQUEST: 'GETINDUSTRYID_REQUEST',
  GETINDUSTRYID_ERROR: 'GETINDUSTRYID_ERROR',
  GETINDUSTRYID_SUCCESS: 'GETINDUSTRYID_SUCCESS'
}

const getIndustry = (lang, per_page, page_number) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETINDUSTRY_REQUEST))
  return await serviceController(`${routes.getIndustry}?lang=${lang}&per_page=${per_page}&page_number=${page_number}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETINDUSTRY_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETINDUSTRY_ERROR, error.message)))
}

const getIndustryById = (industry_id, lang) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETINDUSTRYID_REQUEST))
  return await serviceController(`${routes.getIndustryById}?lang=${lang}&industry_id=${industry_id}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETINDUSTRYID_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETINDUSTRYID_ERROR, error.message)))
}

export const industry = {
  getIndustry,
  getIndustryById
}