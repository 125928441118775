import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  PAGECODE: 'PAGECODE',

  // getPageCode
  GETPAGECODE_REQUEST: 'GETPAGECODE_REQUEST',
  GETPAGECODE_ERROR: 'GETPAGECODE_ERROR',
  GETPAGECODE_SUCCESS: 'GETPAGECODE_SUCCESS',
}

const getPageCode = lang => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETPAGECODE_REQUEST))
  return await serviceController(`${routes.getPageCode}?lang=${lang}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETPAGECODE_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETPAGECODE_ERROR, error.message)))
}

export const aboutUs = {
  getPageCode
}