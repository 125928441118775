import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  BLOG: 'BLOG',

  // getBlog
  GETBLOG_REQUEST: 'GETBLOG_REQUEST',
  GETBLOG_ERROR: 'GETBLOG_ERROR',
  GETBLOG_SUCCESS: 'GETBLOG_SUCCESS',

  // getBlogById
  GETBLOGID_REQUEST: 'GETBLOGID_REQUEST',
  GETBLOGID_ERROR: 'GETBLOGID_ERROR',
  GETBLOGID_SUCCESS: 'GETBLOGID_SUCCESS',

  // getBlogCategory
  GETBLOGCATEGORY_REQUEST: 'GETBLOGCATEGORY_REQUEST',
  GETBLOGCATEGORY_ERROR: 'GETBLOGCATEGORY_ERROR',
  GETBLOGCATEGORY_SUCCESS: 'GETBLOGCATEGORY_SUCCESS'
}

const getBlog = lang => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETBLOG_REQUEST))
  return await serviceController(`${routes.getBlog}?lang=${lang}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETBLOG_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETBLOG_ERROR, error.message)))
}

const getBlogById = (id, lang) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETBLOGID_REQUEST))
  return await serviceController(`${routes.getBlogById}${id}?lang=${lang}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETBLOGID_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETBLOGID_ERROR, error.message)))
}

const getBlogCategory = lang => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETBLOGCATEGORY_REQUEST))
  return await serviceController(`${routes.getBlogCategory}?lang=${lang}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETBLOGCATEGORY_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETBLOGCATEGORY_ERROR, error.message)))
}

export const blog = {
  getBlog,
  getBlogById,
  getBlogCategory
}