import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  ORDER: 'ORDER',

  // getORDER
  GETORDER_REQUEST: 'GETORDER_REQUEST',
  GETORDER_ERROR: 'GETORDER_ERROR',
  GETORDER_SUCCESS: 'GETORDER_SUCCESS',

  // getORDERById
  GETORDERBYID_REQUEST: 'GETORDERBYID_REQUEST',
  GETORDERBYID_ERROR: 'GETORDERBYID_ERROR',
  GETORDERBYID_SUCCESS: 'GETORDERBYID_SUCCESS',

  //getOrderByPDF
  GETORDERBYPDF_REQUEST: 'GETORDERBYPDF_REQUEST',
  GETORDERBYPDF_ERROR: 'GETORDERBYPDF_ERROR',
  GETORDERBYPDF_SUCCESS: 'GETORDERBYPDF_SUCCESS'
}

const getOrder = (lang, user_id, product_per_page, page_number) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETORDER_REQUEST))
  return await serviceController(`${routes.getOrder}?lang=${lang}&user_id=${user_id}&product_per_page=${product_per_page}&page_number=${page_number}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETORDER_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETORDER_ERROR, error.message)))
}

const getOrderById = (order_id, lang) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETORDERBYID_REQUEST))
  return await serviceController(`${routes.getOrderById}?order_id=${order_id}&lang=${lang}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETORDERBYID_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETORDERBYID_ERROR, error.message)))
}

const getOrderByPDF = (order_id) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETORDERBYPDF_REQUEST))
  return await serviceController(`${routes.getOrderByPDF}?order_id=${order_id}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETORDERBYPDF_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETORDERBYPDF_ERROR, error.message)))
}

export const order = {
  getOrder,
  getOrderById,
  getOrderByPDF
}