import { actionTypes } from '../actions/brand.action'

const initialState = {
  error: null,
  isLoading: false,
  brand_data: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETBRAND_REQUEST: // getBrand
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETBRAND_SUCCESS:
      return {
        ...state,
        isLoading: false,
        brand_data: action.data
      }
    case actionTypes.GETBRAND_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}