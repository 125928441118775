const initialState = {
  isType: localStorage.getItem('IS_TYPE') || 'cooperate',
  uiCheck: 'signIn',
  isModal: false,
  isQuickModal: false,
  isCustomerTypeModal: false,
  isCustomerType: localStorage.getItem('isCustomerType_key'),
  userCheck: 'b2c',
  isVerifyOtp: false,
  isSuccess: false,
  isForgetPass: false,
  isForgetPassOTP: false,
  isNewPass: false,
  isProfileData: null,
  isChangePassModal: false,
  isChangePassUiCheck: 'changePass',
  isChangePassSuccess: false,
  isUpdateProfileSuccess: false,
  isUpdateProfileModal: false,
}

const emit = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'COOPERATE':
      localStorage.setItem('IS_TYPE', payload)
      return {
        ...state,
        isType: payload
      }
    case 'ISMODAL':
      return {
        ...state,
        isModal: payload
      }
    case 'ISQUICKMODAL':
      return {
        ...state,
        isQuickModal: payload
      }
    case 'ISCHANGEPASSMODAL':
      return {
        ...state,
        isChangePassModal: payload
      }
    case 'ISCHANGEPASSUICHECK':
      return {
        ...state,
        isChangePassUiCheck: payload
      }
    case 'ISCHANGEPASSSUCCESS':
      return {
        ...state,
        isChangePassSuccess: payload
      }
    case 'ISUICHECK':
      return {
        ...state,
        uiCheck: payload
      }
    case 'USERCHECK':
      return {
        ...state,
        userCheck: payload
      }
    case 'ISOTP':
      return {
        ...state,
        isVerifyOtp: payload
      }
    case 'ISSUCCESS':
      return {
        ...state,
        isSuccess: payload
      }
    case 'ISUPDATEPROFILESUCCESS':
      return {
        ...state,
        isUpdateProfileSuccess: payload
      }
    case 'ISUPDATEPROFILEMODAL':
      return {
        ...state,
        isUpdateProfileModal: payload
      }
    case 'ISFORGETPASS':
      return {
        ...state,
        isForgetPass: payload
      }
    case 'ISFORGETPASSOTP':
      return {
        ...state,
        isForgetPassOTP: payload
      }
    case 'ISNEWPASS':
      return {
        ...state,
        isNewPass: payload
      }
    case 'ISPROFILEDATA':
      return {
        ...state,
        isProfileData: payload
      }
    case 'ISCUSTOMERTYPEMODAL':
      return {
        ...state,
        isCustomerTypeModal: payload
      }
    case 'ISCUSTOMERTYPE':
      localStorage.setItem('isCustomerType_key', payload)
      return {
        ...state,
        isCustomerType: payload
      }
    default:
      return state
  }
}

export default emit