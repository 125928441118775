import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  WEBSITEGALLERY: 'WEBSITEGALLERY',

  // getWebsiteGallery
  GETWEBSITEGALLERY_REQUEST: 'GETWEBSITEGALLERY_REQUEST',
  GETWEBSITEGALLERY_ERROR: 'GETWEBSITEGALLERY_ERROR',
  GETWEBSITEGALLERY_SUCCESS: 'GETWEBSITEGALLERY_SUCCESS',

  // getWebsiteGalleryById
  GETWEBSITEGALLERYID_REQUEST: 'GETWEBSITEGALLERYID_REQUEST',
  GETWEBSITEGALLERYID_ERROR: 'GETWEBSITEGALLERYID_ERROR',
  GETWEBSITEGALLERYID_SUCCESS: 'GETWEBSITEGALLERYID_SUCCESS'
}

const getWebsiteGallery = lang => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETWEBSITEGALLERY_REQUEST))
  return await serviceController(`${routes.getWebsiteGallery}?lang=${lang}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETWEBSITEGALLERY_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETWEBSITEGALLERY_ERROR, error.message)))
}

const getWebsiteGalleryById = (lang, group_code, division_group_code, customer_type) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETWEBSITEGALLERYID_REQUEST))
  return await serviceController(`${routes.getWebsiteGalleryById}?lang=${lang}&group_code=${group_code}&division_group_code=${division_group_code}&customer_type=${customer_type}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETWEBSITEGALLERYID_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETWEBSITEGALLERYID_ERROR, error.message)))
}

export const websiteGallery = {
  getWebsiteGallery,
  getWebsiteGalleryById
}