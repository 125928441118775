import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  CONTACTUS: 'CONTACTUS',

  // getContactUs
  GETCONTACTUS_REQUEST: 'GETCONTACTUS_REQUEST',
  GETCONTACTUS_ERROR: 'GETCONTACTUS_ERROR',
  GETCONTACTUS_SUCCESS: 'GETCONTACTUS_SUCCESS',

  // sentContactUs
  SENTCONTACTUS_REQUEST: 'SENTCONTACTUS_REQUEST',
  SENTCONTACTUS_ERROR: 'SENTCONTACTUS_ERROR',
  SENTCONTACTUS_SUCCESS: 'SENTCONTACTUS_SUCCESS'
}

const getContactUs = lang => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETCONTACTUS_REQUEST))
  return await serviceController(`${routes.getContactUs}?lang=${lang}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETCONTACTUS_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETCONTACTUS_ERROR, error.message)))
}

const sentContactUs = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.SENTCONTACTUS_REQUEST))
  return await serviceController(routes.sentContactUs, data)
    .then(res => {
      if (res.data.result.status === 'success') {
        dispatch(ToDoSuccess(actionTypes.SENTCONTACTUS_SUCCESS, res.data))
        return res.data
      } else {
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.SENTCONTACTUS_ERROR, error.message)))
}

export const contact = {
  getContactUs,
  sentContactUs
}