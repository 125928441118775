import { actionTypes } from '../actions/terms.action'

const initialState = {
  error: null,
  isLoading: false,
  terms_data: null
}

const terms = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETTERMS_REQUEST: // getTermsCondition
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETTERMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        terms_data: action.data
      }
    case actionTypes.GETTERMS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default terms