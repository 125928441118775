import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'
import { addCheckDefault, checkChange, getCheckId } from '../../utils'

export const actionTypes = {
  LOCATION: 'LOCATION',

  // getLocation
  GETLOCATION_REQUEST: 'GETLOCATION_REQUEST',
  GETLOCATION_ERROR: 'GETLOCATION_ERROR',
  GETLOCATION_SUCCESS: 'GETLOCATION_SUCCESS',

  // getCustomerLocation
  GETCUSTOMERLOCATION_REQUEST: 'GETCUSTOMERLOCATION_REQUEST',
  GETCUSTOMERLOCATION_ERROR: 'GETCUSTOMERLOCATION_ERROR',
  GETCUSTOMERLOCATION_SUCCESS: 'GETCUSTOMERLOCATION_SUCCESS',

  // getCountryOrigin
  GETCOUNTRYORIGIN_REQUEST: 'GETCOUNTRYORIGIN_REQUEST',
  GETCOUNTRYORIGIN_ERROR: 'GETCOUNTRYORIGIN_ERROR',
  GETCOUNTRYORIGIN_SUCCESS: 'GETCOUNTRYORIGIN_SUCCESS'
}

const getLocation = () => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETLOCATION_REQUEST))
  return await serviceController(routes.getLocation)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETLOCATION_SUCCESS, res.data))
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETLOCATION_ERROR, error.message)))
}

const getCustomerLocation = () => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETCUSTOMERLOCATION_REQUEST))
  return await serviceController(routes.getCustomerLocation)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETCUSTOMERLOCATION_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETCUSTOMERLOCATION_ERROR, error.message)))
}

const getCountryOrigin = (lang, category_id) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETCOUNTRYORIGIN_REQUEST))
  return await serviceController(`${routes.getCountryOrigin}?lang=${lang}&category_id=${category_id}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        let data = {
          count: res.data.count,
          country_id: null,
          data: addCheckDefault(res.data?.data, 'check')
        }
        dispatch(ToDoSuccess(actionTypes.GETCOUNTRYORIGIN_SUCCESS, data))
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETCOUNTRYORIGIN_ERROR, error.message)))
}

const getCheckChange = (type, array, id) => async dispatch => {
  let res = await checkChange(type, array, id)
  let id_res = await getCheckId(type, res)
  let data = {
    count: res?.length,
    country_id: id_res,
    data: res
  }
  dispatch(ToDoSuccess(actionTypes.GETCOUNTRYORIGIN_SUCCESS, data))
}

export const Location = {
  getLocation,
  getCustomerLocation,
  getCountryOrigin,
  getCheckChange
}