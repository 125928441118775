export const routes = {
  // auth
  signIn: `get:api/auth/token`,
  faceBookSignIn: `post:api/user/login_with_facebook`,
  googleSignIn: `post:user/login_with_social_media`,
  signOut: `delete:api/auth/token`,
  signUp: `post:api/user/signup`,
  sellerSignUp: `post:seller/signup`,
  changePassword: `post:api/password/change`,

  resetPassword: `post:api/user/reset_password`,
  userOtp: `post:api/user/otp_verify`,

  // get state and division 
  getCityList: `get:api/list/city`,

  // customer specialist
  getCustomerSpecialist: `get:api/get/customerspecialist`,

  // for resend otp
  resendOtp: `post:api/user/check`,

  // profile
  getProfile: `get:api/get/profile`,
  updateProfile: `post:api/save/profile/information`,

  // appointment
  getAppointment: `get:api/get/appointments`,
  createAppointment: `post:api/save/appointment`,
  appointmentOtp: `post:appointment/otp_verify`,
  resendAppointmentOtp: `get:appointment/otp_resend`,
  deleteAppointment: `get:appointment/cancel`,

  // privacyPolicy
  getPrivacyPolicy: `get:api/privacy/policy`,

  // translation
  getTranslation: `get:api/text/translation`,

  // wishlist
  createWishlist: `post:api/wishlist/add`,
  getWishlist: `get:api/wishlist`,
  getExistsWishlist: `get:api/exist/wishlist`,
  removeWishlist: `delete:api/wishlist/remove`,

  // order
  getOrder: `get:api/saleorder`,
  getOrderById: `get:api/sale_order/detail`,
  getOrderByPDF: `get:api/download/order_detail`,

  // ecommerce
  createCart: `post:api/save/cart`,
  getCart: `get:api/get/cart`,
  deleteCart: `delete:api/delete/cart`,
  deleteAllInCart: `delete:api/clear/cart`,
  applyPromoCode: `post:api/apply/promocode`,
  createShipping: `post:api/save/shipping/information`,
  uploadDoctorLetter: `post:api/upload/doctor_letters`,
  getPaymentList: `get:api/payment/list`,
  payNow: `post:api/paynow`,
  getPromotion: `get:api/list/promotion`,
  getPromotionById: `get:api/detail/promotion`,

  // home slider
  getEcBanner: `get:api/home_page/slider`,
  getCoBanner: `get:api/website/slider`,

  // division
  getDivision: `get:api/division_list`,
  getDivisionById: `get:api/get/division`,
  getPrinciple: `get:api/get/principlelist`,

  // business
  getBusiness: `get:api/business_list`,
  getBusinessById: `get:api/get/business`,

  // career
  getCareer: `get:api/career_list`,
  getCareerById: `get:api/get/career`,

  // blog
  getBlog: `get:api/blog_list`,
  getBlogById: `get:api/get/blog/`,
  getBlogCategory: `get:api/list/blog/category`,

  // latest new detail
  getLatestNew: `get:api/news_list`,
  getLatestNewById: `get:api/get/news`,

  // aboutUs
  getAboutUs: `get:api/ecommerce/aboutus`,

  // location
  getLocationList: `get:/api/get/locationlist`,

  // Industry
  getIndustry: `get:api/industry_list`,
  getIndustryById: `get:api/get/industry`,

  // contactUs
  getContactUs: `get:api/contact_us`,
  sentContactUs: `post:api/save/contactinformation`,

  // product
  getProduct: `get:api/list/product`,
  getProductById: `get:api/detail/product/list`,
  getProductCategory: `get:api/product/categories`,
  checkProductQty: `get:api/product/quantity`,
  requestQuotation: `post:api/request/quotation`,
  getFeatureProduct: `get:api/get/featureproductlist`,
  getFeatureProductDetail: `get:/api/get/detail/featureproduct`,

  // product brand
  getProductBrand: `get:api/product/brand`,

  // terms and condition
  getTermsCondition: `get:api/website/information`,

  // location
  getLocation: `get:api/list/city`,
  getCustomerLocation: `get:api/customer/city`,
  getCountryOrigin: `get:api/list/country`,

  // website gallery
  getWebsiteGallery: `get:api/list/website/gallery`,
  getWebsiteGalleryById: `get:api/list/website/gallery/detail`,

  // page code
  getPageCode: `get:api/page_code`,

  // online store
  getOnlineStore: `get:api/online_store`
}