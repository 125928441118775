const setOrder = data => {
  localStorage.setItem('zizawar_order_store', JSON.stringify(data))
}

const getOrder = () => {
  return JSON.parse(localStorage.getItem('zizawar_order_store'))
}

const removeOrder = () => {
  localStorage.removeItem('zizawar_order_store')
}

export default {
  setOrder, getOrder, removeOrder
}