import { actionTypes } from '../actions/business.action'

const initialState = {
  error: null,
  isLoading: false,
  business_data: null,
  businessById_data: null
}

const business = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETBUSINESS_REQUEST: // getBusiness
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETBUSINESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        business_data: action.data
      }
    case actionTypes.GETBUSINESS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    case actionTypes.GETBUSINESSID_REQUEST: // getBusinessById
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETBUSINESSID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        businessById_data: action.data
      }
    case actionTypes.GETBUSINESSID_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default business