import { actionTypes } from '../actions/location.action'

const initialState = {
  error: null,
  isLoading: false,
  location_data: null,
  customerLocation_data: null,
  countryorigin_data: null
}

const location = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETLOCATION_REQUEST: // getLocation
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETLOCATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        location_data: action.data
      }
    case actionTypes.GETLOCATION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETCUSTOMERLOCATION_REQUEST: // getCustomerLocation
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETCUSTOMERLOCATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        customerLocation_data: action.data
      }
    case actionTypes.GETCUSTOMERLOCATION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETCOUNTRYORIGIN_REQUEST: // getCountryOrigin
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETCOUNTRYORIGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countryorigin_data: action.data
      }
    case actionTypes.GETCOUNTRYORIGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default location