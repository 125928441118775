import { actionTypes } from '../actions/websitegallery.action'

const initialState = {
  error: null,
  isLoading: false,
  websiteGallery_data: null,
  websiteGalleryById_data: null
}

const websiteGallery = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETWEBSITEGALLERY_REQUEST: // getWebsiteGallery
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETWEBSITEGALLERY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        websiteGallery_data: action.data
      }
    case actionTypes.GETWEBSITEGALLERY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    case actionTypes.GETWEBSITEGALLERYID_REQUEST: // getCareerById
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETWEBSITEGALLERYID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        websiteGalleryById_data: action.data
      }
    case actionTypes.GETWEBSITEGALLERYID_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default websiteGallery