import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  BUSINESS: 'BUSINESS',

  // getBusiness
  GETBUSINESS_REQUEST: 'GETBUSINESS_REQUEST',
  GETBUSINESS_ERROR: 'GETBUSINESS_ERROR',
  GETBUSINESS_SUCCESS: 'GETBUSINESS_SUCCESS',

  // getBusinessById
  GETBUSINESSID_REQUEST: 'GETBUSINESSID_REQUEST',
  GETBUSINESSID_ERROR: 'GETBUSINESSID_ERROR',
  GETBUSINESSID_SUCCESS: 'GETBUSINESSID_SUCCESS'
}

const getBusiness = lang => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETBUSINESS_REQUEST))
  return await serviceController(`${routes.getBusiness}?lang=${lang}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETBUSINESS_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETBUSINESS_ERROR, error.message)))
}

const getBusinessById = (lang, business_id) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETBUSINESSID_REQUEST))
  return await serviceController(`${routes.getBusinessById}?lang=${lang}&business_id=${business_id}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETBUSINESSID_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETBUSINESSID_ERROR, error.message)))
}

export const business = {
  getBusiness,
  getBusinessById
}