import moment from 'moment'

import { Ecommerce } from '../store/actions/ecommerce.action'

/**
 * * This function calculate promotion for product list
 * todo: Let's calculate the promotion price | insert it and return
 * @param data is product list of reducer state
 */
const calcuPromo = data => {
  if (data?.product_list?.length > 0) {
    let temp = []
    let res = data?.product_list?.map((x, i) => {
      if (x?.promotion?.length > 0) {
        let discountPromoData = x?.promotion?.filter(x => x.promotion_type === 'discount')
        let rem = discountPromoData?.map((y, yi) => {
          let tmp = discountPromoData?.filter(e => moment(moment(e.server_date_time).format()).isBetween(moment(e.start_date).format(), moment(e.end_date).format()) || (e.start_date === false && e.end_date === false))
          if (tmp.length > 0) {
            let add = tmp.reduce((total, value) => total + value.discount_percent, 0)
            let km = x.list_price * (add / 100)
            let fr = x.list_price - km
            x.promo_price = fr
          }
        })
        temp.push(x)
      } else {
        x.promo_price = false
        temp.push(x)
      }
    })
    return data
  } else {
    return data
  }
}

/**
 * * This function calculate promotion for product detail
 * todo: Let's calculate the requirements related to promotion | insert it and return
 * @param data is product_detail of reducer state
 * @param qty is product_detail qty
 */
const productPromo = (data, qty) => {
  let discountPromoData = data?.promotion?.filter(x => x.promotion_type === 'discount')
  let tmp = discountPromoData?.filter(e => moment(moment(e.server_date_time).format()).isBetween(moment(e.start_date).format(), moment(e.end_date).format()) || (e.start_date === false && e.end_date === false))

  /**
   * * When calculating a promotion, what is the price based on? It is important. So
   * todo: Let's calculate the currentBasePrice first
   */
  let base_price = data?.list_price?.length > 0 && data?.list_price?.sort((a, b) => a?.min_quantity - b?.min_quantity)?.filter(x => x?.min_quantity <= qty)
  data.currentBasePrice = base_price?.length > 0 ? base_price[base_price.length - 1] : 0

  if (tmp.length > 0) {
    if (tmp?.length > 0) {
      let add = tmp.reduce((total, value) => total + value?.discount_percent, 0)
      let km = data?.currentBasePrice?.fixed_price * (add / 100)
      let fr = data?.currentBasePrice?.fixed_price - km
      data.promo_price = fr
      data.saving_price = data?.currentBasePrice?.fixed_price - fr
      data.totalPromoDiscount = add
      data.totalCurrentPrice = qty * fr
    }
  } else {
    data.promo_price = false
    data.saving_price = false
    data.totalPromoDiscount = false
    data.totalCurrentPrice = qty * data?.currentBasePrice?.fixed_price
  }

  let productPromoData = data?.promotion?.filter(x => x.promotion_type === 'product')
  let ptm = productPromoData?.filter(e => moment(moment(e.server_date_time).format()).isBetween(moment(e.start_date).format(), moment(e.end_date).format()) || (e.start_date === false && e.end_date === false))
  if (ptm?.length > 0) {
    data.promo_product = ptm
  }

  data.receivedPromo = [...tmp, ...ptm]

  return data
}

const breadcrumb = async data => {
  if (data?.data?.length > 0) {
    if (data?.data[0]?.sub_category?.length > 0) {
      let tmp = {
        ...data?.data[0],
        selectIndex: `${data?.data?.indexOf(data?.data[0])}-${data?.data[0]?.sub_category?.indexOf(data?.data[0]?.sub_category[0])}`,
        sub_category: [data?.data[0]?.sub_category[0]]
      }
      data.breadcrumbData = tmp
    } else {
      data.data[0].selectIndex = `${data?.data?.indexOf(data?.data[0])}-0`
      data.breadcrumbData = data?.data[0]
    }
    return data
  }
}

/**
 * * start product add to cart process
 * @param postData
 * @param product_detail
 * @param langCode
 * @param dispatch
 */
const addToCart = (postData, product_detail, langCode) => async dispatch => {

  //* if you does not have order_id in orderStore_data, working this function
  if (!postData?.order_id) {
    delete postData.order_id
    delete postData?.sale_order_line[0]?.cart_status
  }

  //* if you does not have uid in authStore?.getAuth(), working this function
  if (!postData?.user_id) {
    delete postData.user_id
  }

  //* calculate promotion qty | without check variant_id
  if (product_detail?.promo_product?.length > 0) {
    let result = product_detail?.promo_product?.map(x => {
      let tmp = postData?.sale_order_line[0]?.qty
      if (x.buy_product?.length > 0) {
        let buy_res = x.buy_product?.map(b => {
          if (b.product_template_id === +postData?.sale_order_line[0]?.product_id && tmp >= b.min_qty) {
            let tem = x.reward_product?.length > 0 && x.reward_product?.map(y => {
              let pushData = {
                product_id: y.product_template_id,
                cart_status: 'add_cart',
                qty: parseInt(tmp *= y.reward_qty / b.min_qty)
              }
              return pushData?.qty > 0 && postData?.sale_order_line.push(pushData)
            })
          }
        })
      }
    })
  }

  //* if postData?.sale_order_line is longer than 1, it has product promotion
  if (postData?.sale_order_line?.length > 1) {
    let product_promo_res = await dispatch(handleProductPromo(postData, langCode))
    return product_promo_res
  } else {

    //* ဒါက product promotion မရှိတဲ့ အရိုးရှင်းဆုံး add to cart func
    let simple_res = await dispatch(handleSimplePromo(postData, langCode))
    return simple_res
  }
}

const handleProductPromo = (postData, langCode) => async dispatch => {

  /**
   * * sale_order_line ရဲ့ ပထမဆုံး array obj အပေါ်မူတည်းပြီး promotion product ရတဲ့အတွက် ၊ 
   * todo: sale_order_line ရဲ့ ပထမဆုံး array obj နဲ့ အရင်ဆုံး တစ်ခါ  api request လုပ်
   */
  let first_postProd = {
    ...postData,
    sale_order_line: postData?.sale_order_line?.slice(0, 1)
  }

  /**
   * todo: sale_order_line ရဲ့ ပထမဆုံး obj နဲ့ အရင်ဆုံး တစ်ခါ  api request လုပ်
   * todo: အောင်မြင်မှ သူ့နဲ့သက်ဆိုင်တဲ့ promotion product တစ်ခုချင်း တစ်ခုချင်  api request လုပ်
   */
  let res = await dispatch(Ecommerce.createCart(first_postProd, postData?.user_id, langCode))
  if (res?.error) {
    let alert_res = { isModal: true, ui: 'cart_info', msg: 'Out of Stock!', status: 'error' }
    return alert_res
  } else {

    /**
     * * sale_order_line ရဲ့ ပထမဆုံး array obj နဲ့ အရင်ဆုံး တစ်ခါ  api request အောင်မြင်
     * todo: sale_order_line ရဲ့ ပထမဆုံး array obj မပါတဲ့ ကျန်ရှိ  array obj တွေ တစ်ခုချင်းစီ loop လုပ်ပီး api request လုပ်
     * * တစ်ခုချင်းစီ loop လုပ်ပီး api request လုပ်တာမျိုးကို promise all method သုံး
     */
    let qty_calc = 0
    let PromiseApi = postData?.sale_order_line?.slice(1, postData?.sale_order_line.length).map(async y => {
      qty_calc += y.qty
      let sec_postProd = {
        ...postData,
        order_id: res?.result[0]?.order_id,
        sale_order_line: [y]
      }

      let result = await dispatch(Ecommerce.createCart(sec_postProd, postData?.user_id, langCode))

      //* ဘယ် sale_order_line array obj ရဲ့ qty က ဘယ် api request ထွက်လာလဲ သိချင်လို့ y ကို result နဲ့ ပေါင်းထဲ့ပေးလိုက်တယ်
      return {
        ...y,
        result
      }
    })
    let promise_res = Promise.all(PromiseApi).then(async value => {

      /**
       * * တစ်ခုချင်းစီ loop လုပ်ပီး api request အားလုံး လုပ်လို့ပြီးသွားတဲ့ အခါမှ res အနေနဲ့ value array ရတယ်
       * * ဒီ value array ထဲက res result status အားလုံး success ဖြစ်ရင် promotion product process က အပြည့်အ၀ အောင်မြင်တယ်
       */
      if (value?.length > 0 && value.every(x => x?.result?.result[0]?.status === 'success')) {
        let alert_res = { isModal: true, ui: 'cart_info', msg: `You get ${qty_calc} more Free item for promotion!`, status: 'success' }
        return alert_res
      } else {
        /**
         * * promotion product process က အပြည့်အ၀ မအောင်မြင် ပေမယ့် 
         * todo: ကိုယ့်မှာ ရှိသလောက် product ကို promotion ပေးဖို့အတွက် qty ကို ပြန်တွက်
         */
        let fail_res = value?.length > 0 && value.filter(x => x?.result?.result[0]?.status === 'fail')

        //! utils fun ထုတ်လိုက်လို့သာ getCart api request ကို ဒီfunctionထဲ ထည့်ရေးတာ ၊
        //! ပုံမှန်ဆို createCart လုပ်ပီးတိုင်း getCart api request ကို action ထဲမှာကတည်းက ခေါ်ထားပီးသာ:
        let getCart_Data = await dispatch(Ecommerce.getCart(res?.result[0]?.order_id, postData?.user_id || '', langCode))

        //* ကိုယ့်မှာရှိသလောက် productပဲ promotion ပေးဖို့အတွက် qty ကို initial zero အနေနဲ့ ပြန်ထားလိုက်တယ်
        let checkQty = 0
        let PromiseApi2 = fail_res?.map(async y => {

          /**
           * * qty ဘယ်လောက်ပဲပေးနိုင်လဲ တွက်ဖို့အတွက်
           * * getCart_Data ထဲမှာ ရှိတဲ့ product_template_id နဲ့ create cart res တစ်နည်းအားဖြင့် fail_res ထဲမှာ ရှိတဲ့ product_id တူဖို့လိုတယ်
           * * အဲလိုပဲ product variant_id တွေလည်း တူဖိုလိုတယ် ပြီးရင်
           * todo: product_id တို့ variant_id တို့ တူတဲ့ checkID_Variant_Qty array ကို ထုတ်
           */
          let checkID_Variant_Qty = getCart_Data?.data?.length > 0 && getCart_Data?.data?.filter(m => (m.product_template_id === y.product_id) && (y.variant_id ? (m.variant_id === y.variant_id) : true))

          /**
           * todo: promotion product qty ဘယ်လောက်ပဲ ပေးနိုင်တယ်ဆိုတာကို ဆက်တွက်
           * * တွက်နည်း
           * todo: create cart လုပ်လို့ ရလာတဲ့ res ထဲက တစ်နည်းအာဖြင့် သက်ဆိုင်သော fail_res item (eg. y) ထဲကနေ checkID_Variant_Qty[0].quantity ကို နုတ်ရင် ရတဲ့ တန်ဖိုး
           */
          checkQty = y?.result?.result[0]?.available_qty - (checkID_Variant_Qty?.length > 0 ? checkID_Variant_Qty[0]?.quantity : 0)

          /**
           * * checkQty က zero ထက်ကျော်တယ်ဆိုရင် ကျော်သလောက်တန်ဖိုးက ကျနော်တို့ လက်ကျန်ပေးနိုင်တဲ့ promotion product qty ဖြစ်တယ်
           * * zero နဲ့ ညီနေရင်တော့ တစ်ခုမှ promotion product အနေနဲ့ မရပေမယ့်
           * * main product တော့ add to cart process အောင်မြင်တဲ့ သဘော
           */
          if (checkQty >= 0) {
            y.qty = checkQty
            let third_postProd = {
              ...postData,
              order_id: res?.result[0]?.order_id,
              sale_order_line: [{ ...y }]
            }
            delete third_postProd.sale_order_line[0].result
            let temp1 = await dispatch(Ecommerce.createCart(third_postProd, postData?.user_id, langCode))

            //? for testing
            // return temp1
            return {
              insufficient_qty: checkQty,
              result: temp1
            }
          }
        })
        let sec_promise_res = Promise.all(PromiseApi2).then(async value => {
          if (value?.length > 0 && value.every(x => x?.result?.result[0]?.status === 'success')) {
            let alert_res = { isModal: true, ui: 'cart_info', msg: checkQty === 0 ? `Sorry, stock is not enough. So, you can not get any items for this promotion!` : `Sorry, stock is not enough. So you can get some items as we have for this promotion!`, status: 'success' }
            return alert_res
          } else {
            let alert_res = { isModal: true, ui: 'cart_info', msg: 'Out of stock!', status: 'error' }
            return alert_res
          }
        })

        return sec_promise_res
      }
    })
    return promise_res
  }
}

const handleSimplePromo = (postData, langCode) => async dispatch => {
  let res = await dispatch(Ecommerce.createCart(postData, postData?.user_id, langCode))
  if (res?.error) {
    let alert_res = { isModal: true, ui: 'cart_info', msg: 'Out of stock!', status: 'error' }
    return alert_res
  } else {
    let alert_res = { isModal: true, ui: 'cart_info', msg: 'Successfully add to cart!', status: 'success' }
    return alert_res
  }
}
//* end product add to cart process

/**
 * ? Is it on the specified date?
 * * This function will do it
 */
const isBetweenDateRange = data => {
  let tmp = data?.data?.length > 0 && data?.data?.filter(e => moment(moment(e.server_date_time).format()).isBetween(moment(e.start_date).format(), moment(e.end_date).format()) || (e.start_date === false && e.end_date === false))
  let ret_data = {
    ...data,
    data: tmp
  }
  return ret_data
}

export {
  calcuPromo,
  productPromo,
  breadcrumb,
  addToCart,
  handleProductPromo,
  handleSimplePromo,
  isBetweenDateRange
}