import { actionTypes } from '../actions/ecommerce.action'
import { orderStore } from '../../service'

const initialState = {
  error: null,
  isLoading: false,
  createcart_data: null,
  cart_data: null,
  deletecart_data: null,
  deleteallincart_data: null,
  promocode_data: null,
  createshipping_data: null,
  paymentList_data: null,
  payNow_data: null,
  orderStore_data: orderStore.getOrder() || null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATECART_REQUEST: // createCart
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.CREATECART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createcart_data: action.data
      }
    case actionTypes.CREATECART_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETCART_REQUEST: // getCart
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETCART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cart_data: action.data
      }
    case actionTypes.GETCART_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.DELETECART_REQUEST: // deleteCart
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.DELETECART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deletecart_data: action.data
      }
    case actionTypes.DELETECART_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.DELETEALLINCART_REQUEST: // deleteAllInCart
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.DELETEALLINCART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deleteallincart_data: action.data
      }
    case actionTypes.DELETEALLINCART_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.APPLYPROMOCODE_REQUEST: // applyPromoCode
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.APPLYPROMOCODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        promocode_data: action.data
      }
    case actionTypes.APPLYPROMOCODE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.CREATESHIPPING_REQUEST: // createShipping
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.CREATESHIPPING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createshipping_data: action.data
      }
    case actionTypes.CREATESHIPPING_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETPAYMENTLIST_REQUEST: // getPaymentList
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETPAYMENTLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentList_data: action.data
      }
    case actionTypes.GETPAYMENTLIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.PAYNOW_REQUEST: // payNow
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.PAYNOW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        payNow_data: action.data
      }
    case actionTypes.PAYNOW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'ORDER_STORE_OBJ':
      orderStore.setOrder(action.data)
      return {
        ...state,
        orderStore_data: action.data
      }
    case 'ORDER_STORE_REMOVE_OBJ':
      orderStore.removeOrder()
      return {
        ...state,
        cart_data: null,
        orderStore_data: action.data
      }
    default:
      return state
  }
}