import { actionTypes } from '../actions/onlinestore.action'

const initialState = {
  error: null,
  isLoading: false,
  onlineStore_data: null
}

const onlinestore = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETONLINESTORE_REQUEST: // getOnlineStore
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETONLINESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        onlineStore_data: action.data
      }
    case actionTypes.GETONLINESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default onlinestore