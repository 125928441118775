import { actionTypes } from '../actions/blog.action'

const initialState = {
  error: null,
  isLoading: false,
  blog_data: null,
  blogById_data: null,
  blogCategory_data: null
}

const blog = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETBLOG_REQUEST: // getBlog
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETBLOG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        blog_data: action.data
      }
    case actionTypes.GETBLOG_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    case actionTypes.GETBLOGID_REQUEST: // getBlogById
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETBLOGID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        blogById_data: action.data
      }
    case actionTypes.GETBLOGID_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    case actionTypes.GETBLOGCATEGORY_REQUEST: // getCareerCategory
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETBLOGCATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        blogById_data: action.data
      }
    case actionTypes.GETBLOGCATEGORY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default blog