import { actionTypes } from '../actions/citylist.action'

const initialState = {
  error: null,
  isLoading: false,
  cityList_data: null
}

const cityList = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETCITYLIST_REQUEST: // GETCITYLIST
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETCITYLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cityList_data: action.data
      }
    case actionTypes.GETCITYLIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default cityList