import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  ECBANNER: 'ECBANNER',

  // getEcBanner
  GETECBANNER_REQUEST: 'GETECBANNER_REQUEST',
  GETECBANNER_ERROR: 'GETECBANNER_ERROR',
  GETECBANNER_SUCCESS: 'GETECBANNER_SUCCESS',
  
  // getCoBanner
  GETCOBANNER_REQUEST: 'GETCOBANNER_REQUEST',
  GETCOBANNER_ERROR: 'GETCOBANNER_ERROR',
  GETCOBANNER_SUCCESS: 'GETCOBANNER_SUCCESS'
}

const getEcBanner = () => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETECBANNER_REQUEST))
  return await serviceController(`${routes.getEcBanner}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETECBANNER_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETECBANNER_ERROR, error.message)))
}

const getCoBanner = () => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETCOBANNER_REQUEST))
  return await serviceController(`${routes.getCoBanner}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETCOBANNER_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETCOBANNER_ERROR, error.message)))
}

export const banner = {
  getEcBanner,
  getCoBanner
}