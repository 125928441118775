import { actionTypes } from '../actions/customerspecialist.action'

const initialState = {
  error: null,
  isLoading: false,
  customerspecialist_data: null
}

const customerspecialist = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETCUSTOMERSPECIALIST_REQUEST: // getCUSTOMERSPECIALIST
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETCUSTOMERSPECIALIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        customerspecialist_data: action.data
      }
    case actionTypes.GETCUSTOMERSPECIALIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default customerspecialist